import { thunk } from 'easy-peasy';

import {
  DocumentType,
  FilterParams,
  IBurnTokenPayload,
  IProjectAddAuditDocumentsForm,
  IProjectEvent,
  IProjectForm,
  IProjectMilestone,
  IProjectStatement,
  IProjectThunks,
  IStoreModel,
  ISubmitTokensRequestPayload,
  ProjectActionsAndThunks,
} from 'types';
import projectAPI from 'api/project/projectAPI';
import projectRequestAPI from 'api/project/projectRequestAPI';
import { buildCreateDocumentsRequestBody } from 'utils';
import { ErrorTypes } from 'constants/index';
import { PlatformContract } from 'blockchain';

export const thunks: IProjectThunks = {
  loadProjectsList: thunk<ProjectActionsAndThunks, FilterParams>(async (actions, payload) => {
    actions.setProjectLoading(true);

    try {
      const projects = await projectAPI.getProjects(payload);
      actions.setProjectsList(projects);
    } finally {
      actions.setProjectLoading(false);
    }
  }),
  loadTopProjectsList: thunk<ProjectActionsAndThunks, FilterParams>(async (actions, payload) => {
    actions.setProjectLoading(true);

    try {
      const projects = await projectAPI.getProjects(payload);
      actions.setTopProjectsList(projects);
    } finally {
      actions.setProjectLoading(false);
    }
  }),
  loadTopEmissionReductionsProjectsList: thunk<ProjectActionsAndThunks, FilterParams>(async (actions, payload) => {
    actions.setProjectLoading(true);

    try {
      const projects = await projectAPI.getProjects(payload);
      actions.setTopEmissionReductionsProjectsList(projects);
    } finally {
      actions.setProjectLoading(false);
    }
  }),
  loadNewProjectsList: thunk<ProjectActionsAndThunks, FilterParams>(async (actions, payload) => {
    actions.setProjectLoading(true);

    try {
      const projects = await projectAPI.getProjects(payload);
      actions.setNewProjectsList(projects);
    } finally {
      actions.setProjectLoading(false);
    }
  }),
  loadProjectById: thunk<ProjectActionsAndThunks, number, unknown, IStoreModel>(async (actions, payload) => {
    actions.setProjectLoading(true);
    try {
      const projectById = await projectAPI.getProjectById(payload);
      actions.setProjectById(projectById);
    } finally {
      actions.setProjectLoading(false);
    }
  }),
  loadProject: thunk<ProjectActionsAndThunks>(async (actions) => {
    actions.setProjectLoading(true);

    try {
      const project = await projectAPI.getMyProject();
      actions.setProject(project);
    } finally {
      actions.setProjectLoading(false);
    }
  }),
  updateProject: thunk<ProjectActionsAndThunks, IProjectForm, unknown, IStoreModel>(
    async (actions, payload, { getStoreActions }) => {
      const storeActions = getStoreActions();
      const {
        cover,
        pipelineDocuments,
        registrationDocuments,
        issuanceDocuments,
        otherDocuments,
        companyLegalDocuments,
        founderLegalDocuments,
        auditLegalDocuments,
        emissionReductions,
        price,
        ...body
      } = payload;
      if (!body.standard) {
        delete body.standard;
      }

      const uploadUrls = await projectAPI.updateMyProject({
        ...body,
        emissionReductions: emissionReductions.toString(),
        price: Number(price),
        documents: [
          ...buildCreateDocumentsRequestBody(cover, DocumentType.CoverImage),
          ...buildCreateDocumentsRequestBody(pipelineDocuments, DocumentType.VcsPipeline),
          ...buildCreateDocumentsRequestBody(registrationDocuments, DocumentType.VcsRegistration),
          ...buildCreateDocumentsRequestBody(issuanceDocuments, DocumentType.VcsIssuance),
          ...buildCreateDocumentsRequestBody(otherDocuments, DocumentType.VcsOther),
          ...buildCreateDocumentsRequestBody(companyLegalDocuments, DocumentType.CompanyLegal),
          ...buildCreateDocumentsRequestBody(founderLegalDocuments, DocumentType.FounderLegal),
          ...buildCreateDocumentsRequestBody(auditLegalDocuments, DocumentType.AuditCompanyLegal),
        ],
      });

      if (uploadUrls) {
        await storeActions.document.uploadDocumentInputs({
          uploadUrls,
          documents: [
            ...cover,
            ...pipelineDocuments,
            ...registrationDocuments,
            ...issuanceDocuments,
            ...otherDocuments,
            ...companyLegalDocuments,
            ...founderLegalDocuments,
            ...auditLegalDocuments,
          ],
        });
      }
    },
  ),
  submitAuditDocuments: thunk<ProjectActionsAndThunks, IProjectAddAuditDocumentsForm, unknown, IStoreModel>(
    async (actions, payload, { getStoreActions }) => {
      const storeActions = getStoreActions();
      const { documents } = payload;

      const uploadUrls = await projectAPI.submitAuditDocuments({
        documents: [...buildCreateDocumentsRequestBody(documents, DocumentType.AuditCompanyLegal)],
      });

      if (uploadUrls) {
        await storeActions.document.uploadDocumentInputs({
          uploadUrls,
          documents: [...documents],
        });
      }
    },
  ),
  submitProject: thunk<ProjectActionsAndThunks>(async () => {
    await projectAPI.submitMyProject();
  }),

  submitTokensRequest: thunk<ProjectActionsAndThunks, ISubmitTokensRequestPayload, unknown, IStoreModel>(
    async (actions, payload, { getStoreActions }) => {
      if (payload.documents) {
        const storeActions = getStoreActions();
        const uploadUrls = await projectRequestAPI.issueTokens({
          amount: payload.amount,
          type: payload.type,
          documents: buildCreateDocumentsRequestBody(payload.documents),
        });

        if (uploadUrls) {
          await storeActions.document.uploadDocumentInputs({
            uploadUrls,
            documents: payload.documents,
          });
        }
      } else {
        await projectRequestAPI.issueTokens({
          amount: payload.amount,
          type: payload.type,
        });
      }
    },
  ),

  cancelTokenIssue: thunk<ProjectActionsAndThunks, { id: number }>(async (actions, payload) => {
    actions.setProjectLoading(true);
    const { id } = payload;
    try {
      await projectRequestAPI.cancelTokenIssue(id);
      actions.loadProject();
    } finally {
      actions.setProjectLoading(false);
    }
  }),

  //STATEMENTS

  loadProjectStatements: thunk<ProjectActionsAndThunks, { projectId: number }>(async (actions, payload) => {
    const { projectId } = payload;

    const projectStatements = await projectAPI.getProjectStatements(projectId);
    actions.setProjectStatements(projectStatements);
  }),

  addProjectStatement: thunk<ProjectActionsAndThunks, { projectId: number; statement: IProjectStatement }>(
    async (actions, payload) => {
      actions.setProjectLoading(true);

      const { projectId, statement } = payload;
      try {
        await projectAPI.createProjectStatement(projectId, statement);
      } finally {
        actions.setProjectLoading(false);
      }
    },
  ),

  editProjectStatement: thunk<
    ProjectActionsAndThunks,
    { projectId: number; statement: Omit<IProjectStatement, 'id'>; statementId: number }
  >(async (actions, payload) => {
    actions.setProjectLoading(true);

    const { projectId, statement, statementId } = payload;
    try {
      await projectAPI.editProjectStatement(projectId, statement, statementId);
    } finally {
      actions.setProjectLoading(false);
    }
  }),

  deleteProjectStatement: thunk<ProjectActionsAndThunks, { projectId: number; statementId: number }>(
    async (actions, payload) => {
      actions.setProjectLoading(true);

      const { projectId, statementId } = payload;
      try {
        await projectAPI.deleteProjectStatement(projectId, statementId);
      } finally {
        actions.setProjectLoading(false);
      }
    },
  ),

  //PUBLICATIONS

  loadProjectPublications: thunk<ProjectActionsAndThunks, { projectId: number; limit: number }>(
    async (actions, payload) => {
      const projects = await projectAPI.getProjectPublications(payload);
      actions.setProjectPublications(projects);
    },
  ),

  addProjectPublication: thunk<ProjectActionsAndThunks, { projectId: number; publication: any }, unknown, IStoreModel>(
    async (actions, payload, { getStoreActions }) => {
      actions.setProjectLoading(true);
      const { projectId, publication } = payload;
      const storeActions = getStoreActions();
      try {
        const response = await projectAPI.createProjectPublication(projectId, {
          ...publication,
          coverImage: buildCreateDocumentsRequestBody(publication.coverImage, DocumentType.CoverImage)[0],
        });
        await storeActions.document.uploadDocumentInputs({
          uploadUrls: [response.coverImage],
          documents: publication.coverImage,
        });
      } finally {
        actions.setProjectLoading(false);
      }
    },
  ),

  editProjectPublication: thunk<
    ProjectActionsAndThunks,
    { projectId: number; publication: any; publicationId: number },
    unknown,
    IStoreModel
  >(async (actions, payload, { getStoreActions }) => {
    actions.setProjectLoading(true);
    const { projectId, publication, publicationId } = payload;
    const storeActions = getStoreActions();
    try {
      if (Array.isArray(publication.coverImage) && publication.coverImage[0]?.url) {
        delete publication.coverImage;
      }
      const publicationBody = {
        ...publication,
      };

      if (publication.coverImage) {
        publicationBody.coverImage = buildCreateDocumentsRequestBody(
          publication.coverImage,
          DocumentType.CoverImage,
        )[0];
      }
      const editedProject = await projectAPI.editProjectPublication(projectId, publicationBody, publicationId);
      if (publication.coverImage) {
        await storeActions.document.uploadDocumentInputs({
          uploadUrls: [editedProject.coverImage],
          documents: publication.coverImage,
        });
      }
    } finally {
      actions.setProjectLoading(false);
    }
  }),

  deleteProjectPublication: thunk<ProjectActionsAndThunks, { projectId: number; publicationId: number }>(
    async (actions, payload) => {
      actions.setProjectLoading(true);

      const { projectId, publicationId } = payload;
      try {
        await projectAPI.deleteProjectPublication(projectId, publicationId);
      } finally {
        actions.setProjectLoading(false);
      }
    },
  ),

  //MILESTONES
  loadProjectMilestones: thunk<ProjectActionsAndThunks, { projectId: number; limit: number }>(
    async (actions, payload) => {
      const projectMilestones = await projectAPI.getProjectMilestones(payload);
      actions.setProjectMilestones(projectMilestones);
    },
  ),

  addProjectMilestone: thunk<ProjectActionsAndThunks, { projectId: number; milestone: Omit<IProjectMilestone, 'id'> }>(
    async (actions, payload) => {
      actions.setProjectLoading(true);

      const { projectId, milestone } = payload;
      try {
        await projectAPI.createProjectMilestone(projectId, milestone);
      } finally {
        actions.setProjectLoading(false);
      }
    },
  ),

  editProjectMilestone: thunk<
    ProjectActionsAndThunks,
    { projectId: number; milestone: Omit<IProjectMilestone, 'id'>; milestoneId: number }
  >(async (actions, payload) => {
    actions.setProjectLoading(true);

    const { projectId, milestone, milestoneId } = payload;
    try {
      await projectAPI.editProjectMilestone(projectId, milestone, milestoneId);
    } finally {
      actions.setProjectLoading(false);
    }
  }),

  deleteProjectMilestone: thunk<ProjectActionsAndThunks, { projectId: number; milestoneId: number }>(
    async (actions, payload) => {
      actions.setProjectLoading(true);

      const { projectId, milestoneId } = payload;
      try {
        await projectAPI.deleteProjectMilestone(projectId, milestoneId);
      } finally {
        actions.setProjectLoading(false);
      }
    },
  ),

  //EVENTS
  loadProjectEvents: thunk<ProjectActionsAndThunks, { projectId: number; limit: number }>(async (actions, payload) => {
    const projectEvents = await projectAPI.getProjectEvents(payload);
    actions.setProjectEvents(projectEvents);
  }),

  addProjectEvent: thunk<ProjectActionsAndThunks, { projectId: number; event: Omit<IProjectEvent, 'id'> }>(
    async (actions, payload) => {
      actions.setProjectLoading(true);

      const { projectId, event } = payload;
      try {
        await projectAPI.createProjectEvent(projectId, event);
      } finally {
        actions.setProjectLoading(false);
      }
    },
  ),

  editProjectEvent: thunk<
    ProjectActionsAndThunks,
    { projectId: number; event: Omit<IProjectEvent, 'id'>; eventId: number }
  >(async (actions, payload) => {
    actions.setProjectLoading(true);

    const { projectId, event, eventId } = payload;
    try {
      await projectAPI.editProjectEvent(projectId, event, eventId);
    } finally {
      actions.setProjectLoading(false);
    }
  }),

  deleteProjectEvent: thunk<ProjectActionsAndThunks, { projectId: number; eventId: number }>(
    async (actions, payload) => {
      actions.setProjectLoading(true);

      const { projectId, eventId } = payload;
      try {
        await projectAPI.deleteProjectEvent(projectId, eventId);
      } finally {
        actions.setProjectLoading(false);
      }
    },
  ),

  // FAVORITES
  addToFavorites: thunk<ProjectActionsAndThunks, number>(async (actions, payload) => {
    await projectAPI.addToFavorites(payload);
  }),

  removeFromFavorites: thunk<ProjectActionsAndThunks, number>(async (actions, payload) => {
    await projectAPI.removeFromFavorites(payload);
  }),

  loadFavoriteProjectsList: thunk<ProjectActionsAndThunks, FilterParams>(async (actions, payload) => {
    actions.setProjectLoading(true);

    try {
      const favoriteProjects = await projectAPI.getFavoriteProjects(payload);
      if (favoriteProjects.items.length) {
        favoriteProjects.items.forEach((item) => (item.isFavorite = true));
      }
      actions.setFavoriteProjectsList(favoriteProjects);
    } finally {
      actions.setProjectLoading(false);
    }
  }),

  burnTokens: thunk<ProjectActionsAndThunks, IBurnTokenPayload, unknown, IStoreModel>(
    async (actions, payload, { getStoreState }) => {
      const wallet = getStoreState().blockchain.wallet;

      if (!wallet.provider) {
        throw new Error(ErrorTypes.NoMetamask);
      }

      const contract = new PlatformContract(wallet.provider.getSigner());
      return contract.burnTokens(payload.tokenAddr, payload.amount);
    },
  ),
};
